import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
// import PreviewCompatibleImage from './PreviewCompatibleImage'
import Img from 'gatsby-image'
import parse from 'html-react-parser';
import banner from '../img/contact.jpg'


const Contact = ({ bannerItems }) => {
  return (
    <div>
      <section
        className="section"
        style={{
          backgroundColor: `#fffbf2`,
        }}>
        <div className="container">
          <div
            className="banner-wrapper"
          >
            <div className={"columns is-vcentered reverse"}>
              <div className="column is-6">
              <img src={banner} alt="Contact" />
              </div>
              <div className="column is-6">
                <div className="content">
                  <h2 className="title is-3">Get in touch</h2>
                  <p>To book a lesson, ask a question or check availabilty.</p>
                  <Link to="/contact" className="button is-primary">
                    Contact
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}


export default Contact
