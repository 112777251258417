import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Img from 'gatsby-image'
import parse from 'html-react-parser';
import Contact from '../components/Contact'

export const BasicPageTemplate = ({ title, content, contentComponent, frontmatter }) => {
  const PageContent = contentComponent || Content
  return (
    <>
    <section className="testimonial-wrap">
        <div className="container">
          <blockquote className="testimonial">
            {parse(frontmatter.testimonial)}
          </blockquote>
        </div>
      </section>
    <Img style="" fluid={frontmatter.section[0].bannerImg.childImageSharp.fluid} alt="Main banner" />
    <section className="section section--gradient content">
      <div className="container">
      <h1 id="piano">{parse(frontmatter.section[0].title)}</h1>
        <div className="columns">
          <div className="column is-7">
            <PageContent className="content" content={frontmatter.section[0].description} />
          </div>
          <div className="column is-4 is-offset-1">
            <ul className="highlights">
              {frontmatter.section[0].learn.map(item => (
                <li key={item.item}>
                 {item.item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <Img style="" fluid={frontmatter.section[1].bannerImg.childImageSharp.fluid} alt="Main banner" />
    <section className="section section--gradient content">
      <div className="container">
        <h1 id="drums">{parse(frontmatter.section[1].title)}</h1>
        <div className="columns">
          <div className="column is-7">
            <PageContent className="content" content={frontmatter.section[1].description} />
          </div>
          <div className="column is-4 is-offset-1">
            <ul className="highlights">
              {frontmatter.section[1].learn.map(item => (
                <li key={item.item}>
                 {item.item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <Contact />
    </>
  )
}

BasicPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const BasicPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BasicPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        frontmatter={post.frontmatter}
        content={post.html}
      />
    </Layout>
  )
}

BasicPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BasicPage

export const BasicPageQuery = graphql`
  query BasicPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        testimonial
        section {
          title
          bannerImg {
            childImageSharp {
              fluid(quality: 50, maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          learn {
            item
          }
          description
        }
      }
    }
  }
`
